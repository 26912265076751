import {
  AmbassadorAnalyticsRequest,
  AmbassadorAnalyticsResponse,
} from "../Serializers/SerializerModels";
import { fetchPostData } from "../Utilities/FetchUtils";

export const get_ambassador_analytics = async (setResponse) => {
  const { response } = await fetchPostData(
    "ambassador/",
    new AmbassadorAnalyticsRequest({
      start_date: "2024-01-01",
      end_date: "2025-04-01",
    })
  );
  const result = new AmbassadorAnalyticsResponse(response);
  setResponse(result);
};
