import { useEffect, useState } from "react";
import {
  cancelUserSubscription,
  check_status,
  getSubscriptionDetails,
  getSubscriptions,
  user_subscribe,
} from "./UserUtils";
import Loading from "../Common/Loading";
import toast from "react-hot-toast";

const SubscriptionComponent = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [subscriptionDetail, setSubscriptionDetail] = useState([]);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [subscriptionPlans, setSubscriptionPlans] = useState([]);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [subscriptionSuccess, setSubscriptionSuccess] = useState(false);
  const [subscriptionError, setSubscriptionError] = useState(false);
  const [showNotice, setShowNotice] = useState(true);

  useEffect(() => {
    getSubscriptionDetails({ setSubscriptionDetail, setIsLoading });
    getSubscriptions(setSubscriptionPlans);
  }, []);

  const handleSubscribe = (plan) => {
    setButtonLoading(true);
    user_subscribe({
      subscription_id: plan.subscription_id,
      setSubscriptionSuccess: setSubscriptionSuccess,
      setSubscriptionError,
    });
  };

  const handleShowDetails = () => {
    setShowDetailsModal(true);
  };
  const handleCancelModal = () => {
    setShowCancelModal(true);
  };

  const handleNavigateHome = () => {
    window.location.href = "/";
  };
  const bgPriorityColor = {
    1: { bg: "customLightYellow", text: "customBlue", active: "customOrange" },
    2: { bg: "customYellow", text: "customBlue", active: "customBlue" },
    3: { bg: "customBlue", text: "white", active: "customOrange" },
  };

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <div className="flex flex-col md:flex-row mx-[35px] mt-[33px] gap-6 overflow-auto">
          {subscriptionPlans.map((plan) => (
            <div
              key={plan.id}
              className={`relative min-w-[314px] max-w-[314px] h-[554px] flex flex-col pt-[30px] px-[30px] rounded-2xl mb-10 shadow-lg bg-${
                bgPriorityColor[plan.priority]?.bg
              } text-${bgPriorityColor[plan.priority]?.text}`}
            >
              {subscriptionDetail.subscription_type ===
                plan.subscription_type && (
                <div
                  className={`absolute top-0 left-0 px-4 py-2 mt-2 ml-2  bg-${
                    bgPriorityColor[plan.priority]?.active
                  } text-white font-bold text-sm rounded-2xl`}
                >
                  ACTIVE PLAN
                </div>
              )}

              {plan.priority === 1 &&
                subscriptionDetail.subscription_type !==
                  plan.subscription_type && (
                  <div className="absolute top-0 left-0 w-full py-2 bg-customBlue text-white text-center text-sm font-bold rounded-t-2xl">
                    MOST POPULAR
                  </div>
                )}
              <div className="text-xl font-semibold mt-8">
                {plan.subscription_type}
              </div>
              <div className="text-3xl mt-2 font-semibold">
                ₹{plan.subscription_price} /month
              </div>
              <div className="text-sm mt-4 line-clamp-3 h-[60px]">
                {plan.subscription_description}
              </div>
              <div className="flex flex-col mt-[30px] gap-4 flex-grow">
  {[
    `Validity ${plan.subscription_validity} days`,
    plan.subscription_offer_on_book ? (
      <span>
        <span className="font-bold">
          {plan.subscription_offer_book_count}
        </span>{" "}
        Extra books for first month.
      </span>
    ) : (
      <></>
     
    ),
     `${plan.subscription_allowed_book_count} books allowed per month`,
    `${plan.subscription_allowed_book_at_once} books allowed at a time`,
  ].map((text, index) => (
    <div key={index} className="flex flex-row">
      <div
        className={`w-[22px] h-[22px] bg-${
          bgPriorityColor[plan.priority]?.text
        } flex items-center justify-center rounded-full`}
      >
        <i
          className={`fa fa-check ${
            plan.priority !== 3 ? "text-white" : "text-customBlue"
          }`}
        ></i>
      </div>
      <div className="text-base font-semibold ms-3">{text}</div>
    </div>
  ))}
</div>

              <div className="flex mb-[34px] justify-center items-center mt-5 md:mt-0">
                <button
                  className={`w-[114px] h-11 flex items-center justify-center text-base font-semibold rounded-xl bg-white text-customBlue
                  `}
                  onClick={() => {
                    subscriptionDetail.subscription_type ===
                    plan.subscription_type
                      ? handleShowDetails()
                      : handleSubscribe(plan);
                  }}
                  disabled={buttonLoading}
                >
                  {buttonLoading &&
                  subscriptionDetail.subscription_type !==
                    plan.subscription_type
                    ? "Please wait"
                    : subscriptionDetail.subscription_type ===
                      plan.subscription_type
                    ? "See Details"
                    : "Subscribe"}
                </button>
              </div>
            </div>
          ))}
          <div className="relative min-w-[314px] max-w-[314px] h-[554px] flex flex-col pt-[30px] px-[30px] rounded-2xl mb-10 shadow-lg bg-gray-200 text-gray-800">
            <div className="text-xl font-semibold mt-8">Corporate Plan</div>
            <div className="text-3xl mt-2 font-semibold">
              Customized Pricing
            </div>
            <div className="text-sm mt-4">
              Special plans tailored for corporate clients and institutions. Get
              access to flexible book rental options for your employees,
              exclusive offers, and premium customer support.
            </div>
            <div className="flex-grow"></div>
            <div className="flex mb-[34px] justify-center items-center mt-5 md:mt-0">
              <a
                href="mailto:support@readkar.com"
                className="w-[180px] h-11 flex items-center justify-center text-base font-semibold rounded-xl bg-customBlue text-white hover:bg-blue-600 transition"
              >
                Contact Us
              </a>
            </div>
          </div>
        </div>
      )}
      {showDetailsModal && (
        <SubscriptionDetails
          setShowDetailsModal={setShowDetailsModal}
          subscriptionDetail={subscriptionDetail}
          handleCancelModal={handleCancelModal}
        />
      )}

      {showCancelModal && (
        <SubscriptionCancel setShowCancelModal={setShowCancelModal} />
      )}
      {subscriptionSuccess && (
        <SubscriptionSuccess
          setSubscriptionSuccess={setSubscriptionSuccess}
          navigateHome={handleNavigateHome}
        />
      )}
      {subscriptionError && (
        <SubscriptionError
          setSubscriptionError={setSubscriptionError}
          navigateHome={handleNavigateHome}
        />
      )}
      {showNotice && <NoticeDiv setShowNotice={setShowNotice} />}
    </>
  );
};

export default SubscriptionComponent;

const SubscriptionDetails = ({
  setShowDetailsModal,
  subscriptionDetail,
  handleCancelModal,
}) => {
  return (
    <>
      <div className="fixed inset-0 bg-black bg-opacity-60 flex justify-center items-center z-50 transition-opacity duration-300">
        <div className="bg-white rounded-2xl p-8 shadow-2xl w-[90%] max-w-lg transform scale-95 animate-fadeIn transition-all duration-300 ease-in-out">
          <div className="flex justify-between items-center mb-6">
            <h2 className="text-2xl font-semibold text-gray-900">
              Subscription Details
            </h2>
            <button
              onClick={() => setShowDetailsModal(false)}
              className="text-gray-500 hover:text-gray-700 focus:outline-none"
            >
              <i className="fa fa-times text-xl"></i>
            </button>
          </div>
          <div className="text-gray-700 text-sm space-y-4">
            <div className="flex items-center gap-2">
              <i className="fa fa-tag text-customBlue"></i>
              <span className="font-semibold">Type:</span>
              <span>{subscriptionDetail.subscription_type}</span>
            </div>
            <div className="flex items-center gap-2">
              <i className="fa fa-calendar text-customBlue"></i>
              <span className="font-semibold">Start Date:</span>
              <span>
                {new Date(subscriptionDetail.start_date).toLocaleDateString()}
              </span>
            </div>
            <div className="flex items-center gap-2">
              <i className="fa fa-calendar-check text-customBlue"></i>
              <span className="font-semibold">End Date:</span>
              <span>
                {new Date(subscriptionDetail.end_date).toLocaleDateString()}
              </span>
            </div>
            <div className="flex items-center gap-2">
              <i className="fa fa-book text-customBlue"></i>
              <span className="font-semibold">Books Issued:</span>
              <span>{subscriptionDetail.book_issued_count}</span>
            </div>
            <div className="flex items-center gap-2">
              <i className="fa fa-bookmark text-customBlue"></i>
              <span className="font-semibold">Remaining Books:</span>
              <span>{subscriptionDetail.remaining_book_count}</span>
            </div>
            <div className="flex items-center gap-2">
              <i className="fa fa-check-circle text-customBlue"></i>
              <span className="font-semibold">Allowed Books At Once:</span>
              <span>{subscriptionDetail.allowed_book_at_once}</span>
            </div>
          </div>
          <div className="mt-8 flex justify-center gap-4">
            <button
              onClick={() => handleCancelModal()}
              className="bg-red-600 text-white px-6 py-2 rounded-xl shadow-lg hover:bg-red-700 transition-all duration-200 ease-in-out"
            >
              Cancel
            </button>
            <button
              onClick={() => setShowDetailsModal(false)}
              className="bg-gray-200 text-gray-800 px-6 py-2 rounded-xl shadow-lg hover:bg-gray-300 transition-all duration-200 ease-in-out"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

const SubscriptionCancel = ({ setShowCancelModal }) => {
  const handleCancel = async () => {
    try {
      // Replace with your actual cancel function
      await cancelUserSubscription({toast});
      setShowCancelModal(false);
    } catch (error) {
      toast.error('Failed to cancel subscription. Please try again.');
    }
  };
  return (
    <>
      <div className="fixed inset-0 bg-black bg-opacity-60 flex justify-center items-center z-50">
        <div className="bg-white rounded-2xl p-6 shadow-xl w-96 max-w-full">
          <h2 className="text-xl font-semibold text-gray-800 mb-4 text-center">
            Cancel Subscription
          </h2>
          <p className="text-gray-600 text-sm mb-6 text-center">
            Are you sure you want to cancel your subscription?
            <br />
            <br />
            ⚠ You must return all borrowed books to complete the cancellation
            process.
            <br />
            <br />
            Once cancelled, you will no longer be able to rent books.
          </p>
          <div className="flex justify-center gap-4">
            <button
              onClick={handleCancel} // Add your cancel confirmation function here
              className="bg-red-600 text-white px-4 py-2 rounded-lg hover:bg-red-700 transition"
            >
              Yes, Cancel
            </button>
            <button
              onClick={() => setShowCancelModal(false)}
              className="bg-gray-200 text-gray-800 px-4 py-2 rounded-lg hover:bg-gray-300 transition"
            >
              No, Go Back
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
const SubscriptionSuccess = ({ setSubscriptionSuccess, navigateHome }) => {
  return (
    <>
      <div className="fixed inset-0 bg-black bg-opacity-60 flex justify-center items-center z-50">
        <div className="bg-white rounded-2xl p-6 shadow-xl w-96 max-w-full">
          <h2 className="text-xl font-semibold text-gray-800 mb-4 text-center">
            Payment Confirmation
          </h2>
          <p className="text-gray-600 text-sm mb-6 text-center">
            We are verifying your payment details.
            <br />
            <br />
            ⚡ If the payment is successful, your subscription will be activated
            within 5 to 10 minutes.
            <br />
            <br />
            You can check your subscription status on the home page.
          </p>
          <div className="flex justify-center gap-4">
            <button
              onClick={() => setSubscriptionSuccess(false)}
              className="bg-gray-200 text-gray-800 px-4 py-2 rounded-lg hover:bg-gray-300 transition"
            >
              Close
            </button>
            <button
              onClick={navigateHome}
              className="bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700 transition"
            >
              Go to Home
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

const SubscriptionError = ({ setSubscriptionError, navigateHome }) => {
  return (
    <>
      <div className="fixed inset-0 bg-black bg-opacity-60 flex justify-center items-center z-50">
        <div className="bg-white rounded-2xl p-6 shadow-xl w-96 max-w-full">
          <h2 className="text-xl font-semibold text-red-600 mb-4 text-center">
            Subscription Error
          </h2>
          <p className="text-gray-600 text-sm mb-6 text-center">
            ❌ Oops! Something went wrong while processing your subscription.
            <br />
            <br />
            Please retry in a few minutes. If the problem persists, contact
            support.
          </p>
          <div className="flex justify-center gap-4">
            <button
              onClick={() => setSubscriptionError(false)}
              className="bg-gray-200 text-gray-800 px-4 py-2 rounded-lg hover:bg-gray-300 transition"
            >
              Close
            </button>
            <button
              onClick={navigateHome}
              className="bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700 transition"
            >
              Go to Home
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

const NoticeDiv = ({ setShowNotice }) => {
  const handleBackdropClick = (e) => {
    // Close the modal only if the click is outside the modal container
    if (e.target === e.currentTarget) {
      setShowNotice(false);
    }
  };
  return (
    <>
      <div className="fixed inset-0 bg-black bg-opacity-70 flex justify-center items-center z-50 animate-fadeIn" onClick={handleBackdropClick}>
        <div className="bg-white rounded-2xl p-8 shadow-2xl w-96 max-w-full transform transition-transform scale-95 hover:scale-100" onClick={(e) => e.stopPropagation()} >
          <h2 className="text-2xl font-bold text-customBlue mb-4 text-center">
            📢 Important Notice
          </h2>
          <p className="text-gray-700 text-base mb-6 text-center leading-relaxed">
            We currently offer book rentals exclusively in Pune.
            <br />
            <br />
            Want to see us in your city? We'd love to hear from you! Let us know
            by reaching out.
          </p>
          <div className="flex flex-col gap-4 items-center">
            <button
              onClick={() => setShowNotice(false)}
              className="bg-customBlue text-white px-5 py-2 rounded-lg hover:bg-indigo-700 transition-all duration-300 ease-in-out transform hover:scale-105"
            >
              Got it! Take me back
            </button>
            <a
              href="mailto:support@readkar.com"
              className="bg-gray-100 text-indigo-600 px-5 py-2 rounded-lg border border-indigo-300 hover:bg-indigo-50 transition-all duration-300 ease-in-out transform hover:scale-105"
            >
              Contact Us
            </a>
          </div>
        </div>
      </div>

      {/* Optional fade-in animation */}
      <style jsx>{`
        .animate-fadeIn {
          animation: fadeIn 0.5s ease-out;
        }

        @keyframes fadeIn {
          from {
            opacity: 0;
          }
          to {
            opacity: 1;
          }
        }
      `}</style>
    </>
  );
};
