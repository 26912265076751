import { BookModel, SearchRequest } from "../Serializers/SerializerModels";
import { fetchGetData, fetchPostDataNoAuth } from "../Utilities/FetchUtils";
export const getBookByGenre = async (genre, setBooks, setIsLoading) => {
  setIsLoading(true);
  const { response, error } = await fetchPostDataNoAuth(
    "books/get_by_genre",
    new SearchRequest(genre, 30)
  );
  if (response) {
    const books = response.book_details.map((book) => new BookModel(book));
    setBooks(books);
  } else {
    return [];
  }
  setIsLoading(false);
};

export const getTrendingBooks = async (setBooks, setIsLoading) => {
  setIsLoading(true);
  const { response, error } = await fetchGetData("books/trending");
  if (response) {
    const books = response.book_details.map((book) => new BookModel(book));
    setBooks(books);
  } else {
    return [];
  }
  setIsLoading(false);
};
