import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import BooksComponent from "../Books/BooksComponent";
import { handleSearch, loadMore } from "./SearchUtils";
import Loading from "../Common/Loading";
import MbTitleBar from "../Common/MbTitleBar";
import { ReactComponent as OpenBook } from "../../Assets/icons/open_book.svg";
import RequestBookComponent from "../RequestBook/RequestBookComponent";
import sad_dog from "../../Assets/icons/sad-dog-1.png";
import book_missing from "../../Assets/icons/missing-book.png";
import { debounce } from "lodash";

const SearchComponent = ({
  searchTerm,
  setSearchTerm,
  handleKeyPress,
  handleSearchRedirect,
  setOpenBook,
  orderBook,
  setIsSearchTrue,
}) => {
  const navigate = useNavigate();

  const [booksDetails, setBooksDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isRequestBook, setIsRequestBook] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [isMoreLoading, setIsMoreLoading] = useState(false);
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(50);

  const debouncedSearch = useRef(
    debounce((term) => {
      if (term.trim() !== "") {
        setOffset(0);
        handleSearch({
          searchTerm: term,
          setBooksDetails,
          setIsLoading,
          setError,
          offset: 0,
          limit,
          setOffset,
          setHasMore,
        });
      }
    }, 500)
  ).current;

  useEffect(() => {
    debouncedSearch(searchTerm);
  }, [searchTerm]);

  const handleLoadMore = () => {
    loadMore({
      searchTerm,
      setBooksDetails,
      setIsMoreLoading,
      setError,
      offset,
      limit,
      setOffset,
      setHasMore,
    });
  };

  return (
    <div className="flex flex-col h-full ">
      <div className="flex flex-col md:hidden">
        <MbTitleBar />
        <div className="w-full flex flex-row px-4 items-center">
          <div className="w-1/12">
            <i
              className="fa fa-arrow-left mr-4 fa-lg cursor-pointer"
              onClick={() => {
                setIsSearchTrue(false);
                setSearchTerm("");
              }}
            />
          </div>
          <div className="w-11/12 h-[48px] border rounded-3xl justify-start px-4 items-center flex my-6">
            <input
              className="text-lg flex-grow placeholder-gray-500 focus:outline-none"
              type="text"
              placeholder="Search"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              onKeyPress={handleKeyPress}
            />
            <button
              className="p-4 text-gray-500 hover:text-gray-700"
              onClick={() => handleSearchRedirect(searchTerm)}
              aria-label="Search books"
            >
              <i className="fa-solid fa-search" />
            </button>
          </div>
        </div>
      </div>

      <div className="hidden md:flex flex-row w-full h-[84px] px-[35px] pt-[22px] pb-[12px] border-b border-gray-200 ">
        <div className="w-1/12 items-center justify-center flex">
          <i
            className="fa fa-arrow-left mr-4 fa-lg cursor-pointer"
            onClick={() => {
              setIsSearchTrue(false);
              setSearchTerm("");
            }}
          />
        </div>
        <div className="w-5/12 items-center flex">
          <h1 className="text-2xl font-semibold">Home</h1>
        </div>
        <div className="w-6/12 flex justify-end">
          <div className="w-[484px] h-[40] border rounded-3xl justify-start px-4 items-center flex">
            <input
              className="text-lg flex-grow placeholder-gray-500 focus:outline-none"
              type="text"
              placeholder="Search books by name/author/genre"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              onKeyPress={handleKeyPress}
            />
            <button
              className="p-4 text-gray-500 hover:text-gray-700"
              onClick={() => handleSearchRedirect(searchTerm)}
              aria-label="Search books"
            >
              <i className="fa-solid fa-search" />
            </button>
          </div>
        </div>
      </div>

      {isRequestBook ? (
        <RequestBookComponent />
      ) : isLoading ? (
        <Loading />
      ) : (
        <div className="h-full overflow-y-auto">
          <div className="w-full grid grid-cols-2 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 gap-5 px-[22px] pt-6">
            {booksDetails.length > 0 &&
              booksDetails.map((book, index) => (
                <div
                  key={index}
                  className="bg-[#F7F9FB] px-3 py-[16px] flex flex-col rounded-lg cursor-pointer"
                  onClick={() => setOpenBook(book)}
                >
                  {book.image_url ? (
                    <img
                      src={book.image_url}
                      alt={book.title}
                      className="w-[78px] h-[117px] object-cover border"
                    />
                  ) : (
                    <div className="w-[78px] h-[117px] justify-items-center">
                      <img
                        src={book_missing}
                        alt="Book Missing"
                        className="object-cover border"
                      />
                    </div>
                  )}
                  <div className="flex flex-col h-[71px] mt-[16px]">
                    <div className="text-base font-semibold line-clamp-2">
                      {book.title}
                    </div>
                    <div className="text-xs text-gray-500 line-clamp-1">
                      By {book.author}
                    </div>
                  </div>
                  <div className="w-full justify-start items-start">
                    <button
                      onClick={orderBook}
                      className="flex flex-row items-center w-[113px] bg-customBlue justify-center text-white h-11 rounded-lg"
                    >
                      <OpenBook width="19" height="19" fill="currentColor" />
                      <span className="ml-1 text-sm">Rent Now</span>
                    </button>
                  </div>
                </div>
              ))}
          </div>

          {hasMore && booksDetails.length > 0 && (
            <div className="w-full flex items-center justify-center my-6">
              {isMoreLoading ? (
                <div className="w-6 h-6 border-4 border-blue-500 border-t-transparent rounded-full animate-spin"></div>
              ) : (
                <button
                  onClick={handleLoadMore}
                  className="bg-customSkyBlue text-customBlue px-6 py-2 rounded-lg hover:bg-customBlue hover:text-white transition"
                >
                  See More
                </button>
              )}
            </div>
          )}

          <div className="w-full flex items-center justify-center my-4 mb-[45px]">
            <div className="w-11/12 h-[1px] bg-gray-400"></div>
          </div>
          <div className="w-full flex flex-col mb-40 px-6">
            <div className="w-full flex items-center justify-center flex-col">
              <img
                src={sad_dog}
                alt="Logo"
                className="w-[153px] h-[150px]"
              />
              <div className="text-2xl text-customBlue font-bold text-wrap text-center">
                Couldn't Find the book you are looking for?
              </div>
            </div>
            <div className="w-full flex items-center justify-center">
              <button
                onClick={() => setIsRequestBook(true)}
                className="mt-4 bg-customSkyBlue hover:bg-customBlue text-customBlue hover:text-white font-bold py-2 px-4 rounded"
              >
                Request Book
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SearchComponent;
