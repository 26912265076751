import React, { useEffect, useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { QRCodeCanvas } from "qrcode.react";
import {
  WhatsappShareButton,
  TelegramShareButton,
  EmailShareButton,
  FacebookShareButton,
  TwitterShareButton,
  WhatsappIcon,
  TelegramIcon,
  EmailIcon,
  FacebookIcon,
  TwitterIcon
} from "react-share";
import toast, { Toaster } from "react-hot-toast";
import { getCurrentUser } from "../User/UserUtils";

const PersonalInfoShare = ({ userInfo}) => {
  const user = {
    name: "Aarav Sharma",
    email: "aarav.sharma@example.com",
    inviteCode: "RS123456"
  };


  const inviteLink = `https://readkar.com/signup?invite_code=${userInfo ? userInfo.invite_code : ""}`;
  const shareMessage = `Join ReadKar with my invite link and discover amazing books! 📚\n${inviteLink}`;

  const handleCopy = (label) => {
    toast.success(`${label} copied to clipboard!`);
  };

  return (
    <div className="max-w-4xl mx-auto mt-10 px-4 sm:px-6 lg:px-8 py-8 bg-white/80 backdrop-blur-md rounded-3xl shadow-2xl border border-gray-200">
      <Toaster position="top-right" />

      {/* Header */}
      <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between gap-2 mb-6">
        <h2 className="text-2xl sm:text-3xl font-bold text-gray-900 flex items-center gap-2">
          <i className="fa-solid fa-id-badge text-blue-600 text-xl sm:text-2xl"></i>
          Ambassador Panel
        </h2>
        <span className="bg-blue-100 text-blue-600 text-xs font-medium px-3 py-1 rounded-full">
          Ambassador 
        </span>
      </div>

      {/* Info Grid */}
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-6">
        <div className="bg-white border rounded-xl p-4 shadow-sm">
          <p className="text-xs text-gray-500 uppercase mb-1">Name</p>
          <p className="text-base font-semibold text-gray-800">{userInfo ? userInfo.first_name : ""}</p>
        </div>
        <div className="bg-white border rounded-xl p-4 shadow-sm">
          <p className="text-xs text-gray-500 uppercase mb-1">Email</p>
          <p className="text-base font-semibold text-gray-800">{userInfo ? userInfo.last_name : ""}</p>
        </div>
      </div>

      {/* Invite Code */}
      <div className="flex flex-col sm:flex-row sm:items-center justify-between bg-gray-50 border rounded-xl p-4 mb-4 shadow-inner">
        <div className="mb-2 sm:mb-0">
          <p className="text-xs text-gray-500 uppercase mb-1">Invite Code</p>
          <p className="font-mono text-lg text-gray-700">{userInfo ? userInfo.invite_code : ""}</p>
        </div>
        <CopyToClipboard text={user.inviteCode} onCopy={() => handleCopy("Invite code")}>
          <i className="fa-regular fa-copy text-2xl text-blue-500 hover:text-blue-700 cursor-pointer transition" />
        </CopyToClipboard>
      </div>

      {/* Invite Link */}
      <div className="flex flex-col sm:flex-row sm:items-center justify-between bg-gray-50 border rounded-xl p-4 mb-6 shadow-inner">
        <div className="overflow-hidden mb-2 sm:mb-0">
          <p className="text-xs text-gray-500 uppercase mb-1">Invite Link</p>
          <p className="text-sm text-gray-800 font-medium truncate max-w-[90vw]">{inviteLink}</p>
        </div>
        <CopyToClipboard text={inviteLink} onCopy={() => handleCopy("Invite link")}>
          <i className="fa-regular fa-copy text-2xl text-blue-500 hover:text-blue-700 cursor-pointer transition" />
        </CopyToClipboard>
      </div>

      {/* QR Code */}
      <div className="text-center mb-8">
        <p className="text-sm text-gray-500 mb-2">Scan to share</p>
        <div className="inline-block border-2 border-gray-200 bg-white rounded-2xl p-4 shadow-lg hover:scale-105 transition">
          <QRCodeCanvas value={inviteLink} size={140} />
        </div>
      </div>

      {/* Share Buttons */}
      <div>
        <p className="text-sm text-gray-500 mb-3">Share via</p>
        <div className="flex flex-wrap gap-3 justify-center sm:justify-start">
          <WhatsappShareButton url={inviteLink} title={shareMessage}>
            <WhatsappIcon size={48} round />
          </WhatsappShareButton>
          <TelegramShareButton url={inviteLink} title={shareMessage}>
            <TelegramIcon size={48} round />
          </TelegramShareButton>
          <EmailShareButton url={inviteLink} subject="Join ReadKar!" body={shareMessage}>
            <EmailIcon size={48} round />
          </EmailShareButton>
          <FacebookShareButton url={inviteLink} quote={shareMessage}>
            <FacebookIcon size={48} round />
          </FacebookShareButton>
          <TwitterShareButton url={inviteLink} title={shareMessage}>
            <TwitterIcon size={48} round />
          </TwitterShareButton>
        </div>
      </div>
    </div>
  );
};

export default PersonalInfoShare;
