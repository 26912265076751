import React, { useEffect, useState } from "react";
import {
  LineChart,
  Line,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  CartesianGrid,
  PieChart,
  Pie,
  Cell
} from "recharts";
import dayjs from "dayjs";
import isoWeek from "dayjs/plugin/isoWeek";
import { get_ambassador_analytics } from "./AmbassadorUtils";

dayjs.extend(isoWeek);

const COLORS = ["#3b82f6", "#10b981"];

const Performance = ({userInfo}) => {
  const [performanceData, setPerformanceData] = useState({ users: [] });
  const [view, setView] = useState("monthly");
  const [chartData, setChartData] = useState([]);
  const [referralTotal, setReferralTotal] = useState(0);
  const [referralThisMonth, setReferralThisMonth] = useState(0);
  const [response, setResponse] = useState(null);

useEffect(() => {
  get_ambassador_analytics(setResponse,userInfo)
},[])


  useEffect(() => {
    if (!response) return;
    setPerformanceData(response);
    updateStats(response.users);
    generateChartData(response.users, view);
  }, [view,response]);

  const updateStats = (users) => {
    const total = users.reduce((sum, u) => sum + u.earning, 0);
    const thisMonth = dayjs().format("YYYY-MM");
    const thisMonthTotal = users
      .filter((u) => dayjs(u.creation_timestamp).format("YYYY-MM") === thisMonth)
      .reduce((sum, u) => sum + u.earning, 0);
    setReferralTotal(total);
    setReferralThisMonth(thisMonthTotal);
  };

  const generateChartData = (users, viewMode) => {
    const map = {};

    users.forEach((u) => {
      const date = dayjs(u.creation_timestamp);
      let key = "";

      if (viewMode === "monthly") {
        key = date.format("MMM YYYY");
      } else if (viewMode === "weekly") {
        key = `Week ${date.isoWeek()} ${date.format("YYYY")}`;
      } else {
        key = date.format("DD MMM");
      }

      if (!map[key]) map[key] = { period: key, signups: 0, paid: 0 };
      map[key].signups += 1;
      if (u.has_active_subscription) map[key].paid += 1;
    });

    const sorted = Object.values(map).sort((a, b) =>
      dayjs(a.period.split(" ").slice(-2).join(" ")).isAfter(dayjs(b.period.split(" ").slice(-2).join(" "))) ? 1 : -1
    );

    setChartData(sorted);
  };

  const totalSignups = performanceData.users.length;
  const totalPaid = performanceData.users.filter((u) => u.has_active_subscription).length;

  const pieData = [
    { name: "not subscribed", value: totalSignups - totalPaid },
    { name: "subscribed", value: totalPaid }
  ];

  return (
    <div className="w-full">
      <h2 className="text-xl font-semibold text-gray-800 mb-6 flex items-center gap-2">
        <i className="fa-solid fa-chart-column text-blue-600"></i>
        Performance Overview
      </h2>

      {/* Stat Cards */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 mb-8">
        <div className="bg-white border rounded-xl p-4 shadow text-center">
          <p className="text-sm text-gray-500">Total Invites</p>
          <p className="text-2xl font-bold text-blue-600">{totalSignups}</p>
        </div>
        <div className="bg-white border rounded-xl p-4 shadow text-center">
          <p className="text-sm text-gray-500">Paid Users</p>
          <p className="text-2xl font-bold text-green-500">{totalPaid}</p>
        </div>
        <div className="bg-white border rounded-xl p-4 shadow text-center">
          <p className="text-sm text-gray-500">Referral Rewards</p>
          <p className="text-2xl font-bold text-amber-600">₹{referralTotal}</p>
        </div>
        <div className="bg-white border rounded-xl p-4 shadow text-center">
          <p className="text-sm text-gray-500">This Month</p>
          <p className="text-2xl font-bold text-purple-600">₹{referralThisMonth}</p>
        </div>
      </div>

      {/* Chart + Dropdown */}
      <div className="bg-white rounded-xl shadow p-4 mb-8">
        <div className="flex items-center justify-between mb-4">
          <h3 className="text-sm font-semibold text-gray-600">Signups vs Paid Users ({view})</h3>
          <select
            value={view}
            onChange={(e) => setView(e.target.value)}
            className="border rounded-md px-3 py-1 text-sm focus:outline-none"
          >
            <option value="monthly">Monthly</option>
            <option value="weekly">Weekly</option>
            <option value="daily">Daily</option>
          </select>
        </div>

        <ResponsiveContainer width="100%" height={250}>
          <LineChart data={chartData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="period" />
            <YAxis allowDecimals={false} />
            <Tooltip />
            <Legend />
            <Line type="monotone" dataKey="signups" stroke="#3b82f6" strokeWidth={2} />
            <Line type="monotone" dataKey="paid" stroke="#10b981" strokeWidth={2} />
          </LineChart>
        </ResponsiveContainer>
      </div>

      {/* Pie Chart */}
      <div className="bg-white rounded-xl shadow p-4">
        <h3 className="text-sm font-semibold text-gray-600 mb-2">User Conversion Ratio</h3>
        <ResponsiveContainer width="100%" height={250}>
          <PieChart>
            <Pie
              data={pieData}
              dataKey="value"
              nameKey="name"
              cx="50%"
              cy="50%"
              outerRadius={80}
              label
            >
              {pieData.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
              ))}
            </Pie>
            <Tooltip />
            <Legend />
          </PieChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default Performance;
