import React, { useEffect, useState } from "react";
import PersonalInfoShare from "./PersonalInfoShare";
import Performance from "./Performance";
import { getCurrentUser } from "../User/UserUtils";

const AmbassadorDashboard = () => {
  const [activeTab, setActiveTab] = useState("personal");
  const [userInfo, setUserInfo] = useState(null);
  useEffect(() => {
    getCurrentUser({ setUserInfo, setError: () => {} });
  }, []);
  const tabStyle = (tab) =>
    `px-4 py-2 rounded-t-lg text-sm font-medium transition ${
      activeTab === tab
        ? "bg-white text-blue-600 border-t-2 border-blue-500"
        : "text-gray-500 hover:text-blue-500"
    }`;

  return (
    <div className="max-w-5xl mx-auto mt-10 px-4">
      <div className="flex space-x-4 border-b border-gray-300 mb-4">
        <button
          onClick={() => setActiveTab("personal")}
          className={tabStyle("personal")}
        >
          <i className="fa-solid fa-user mr-1" /> Ambassador Panel
        </button>
        <button
          onClick={() => setActiveTab("performance")}
          className={tabStyle("performance")}
        >
          <i className="fa-solid fa-chart-line mr-1" /> Performance
        </button>
      </div>

      <div className="rounded-xl bg-gray-50 p-4 sm:p-6 shadow-md">
        {activeTab === "personal" && <PersonalInfoShare userInfo={userInfo} />}
        {activeTab === "performance" && <Performance userInfo={userInfo} />}
      </div>
    </div>
  );
};

export default AmbassadorDashboard;
